import React from "react";
import {
	Box,
	Radio,
	FormControl,
	RadioGroup,
	Typography
} from "@ellevation/backpack";
import { usePoc } from "../../PocContextProvider";
import { ListeningBar } from "../../components";

export const Template6 = ({ question }) => {
	const { updateResponses, currentContentIndex, responses } = usePoc();

	const handleChange = event => {
		updateResponses(currentContentIndex, event.target.value);
	};

	return (
		<Box sx={{ mt: 4, width: "1131px", mx: "auto" }}>
			<Typography
				sx={{
					backgroundColor: "#F9F9F9",
					mt: 2,
					mx: 2,
					py: 2,
					px: 1,
					fontSize: "24px",
					textAlign: "center"
				}}
				variant="h4">
				{question.title}
			</Typography>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box sx={{ ml: 2, mt: 3, mr: 12.5, width: "495px" }}>
					{question.textA && (
						<ListeningBar text={question.textA} id={currentContentIndex} />
					)}
					{question.stimulus && (
						<img
							style={{
								maxWidth: "100%",
								marginTop: "16px",
								borderRadius: "8px"
							}}
							src={question.stimulus}
							alt="stimulus image"
						/>
					)}
				</Box>
				<Box sx={{ mt: 3, width: "45%" }}>
					<Box sx={{ display: "flex", width: "98.5%" }}>
						<Box sx={{ mr: 2.5, mt: 3.5, fontSize: "21px", fontWeight: "600" }}>
							{question.questionNumber}
						</Box>
						<ListeningBar text={question.textA} id={currentContentIndex} />
					</Box>
					<FormControl component="fieldset">
						<RadioGroup sx={{ ml: 3, mt: 1, fontSize: "21px" }}>
							{question.options.map((choice, index) => {
								return (
									<Box
										sx={{
											mt: 1,
											mb: "12px",
											ml: 12,
											width: "270px",
											height: "134px",
											fontSize: "21px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
										<Radio
											checked={
												index ==
												responses[currentContentIndex]?.question?.response
											}
											key={index}
											name={`option ${index}`}
											onChange={handleChange}
											value={index}
										/>
										<img
											style={{
												width: "100%",
												height: "100%",
												mt: 2,
												borderRadius: "4px",
												paddingx: "-16px"
											}}
											src={choice}
											alt="stimulus image"
										/>
									</Box>
								);
							})}
						</RadioGroup>
					</FormControl>
				</Box>
			</Box>
		</Box>
	);
};
